<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialogShow"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="isAdd ? '新增': '编辑支付方式'"
    width="600px"
    class="pay-add-or-edit-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="122px"
    >
      <el-form-item label="支付类型" prop="type">
        <el-radio v-model="form.type" :disabled="typeDisabled" label="1">入金</el-radio>
        <el-radio v-model="form.type" :disabled="typeDisabled" label="2">出金</el-radio>
        <el-radio v-model="form.type" :disabled="typeDisabled" label="3">出佣</el-radio>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <el-radio v-model="form.status" label="0">启用</el-radio>
        <el-radio v-model="form.status" label="1">禁用</el-radio>
      </el-form-item>

      <el-form-item prop="name" label="支付方式名称">
        <el-input
          placeholder="允许中、英文和数字,限制20个字符以内"
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item prop="nameHant" label="繁体名称">
        <el-input
          placeholder="允许字母数字空格限制20个字符以内"
          v-model="form.nameHant"
          maxLength="20"
        />
      </el-form-item>
      <el-form-item prop="nameEn" label="英文名称">
        <el-input
          placeholder="允许字母数字空格限制20个字符以内"
          v-model="form.nameEn"
          @input="channelNameEnInputHandle"
          maxLength="20"
        />
      </el-form-item>
      <el-form-item prop="minMoney" label="最小限额">
        <el-input
          class="min-money"
          v-model="form.minMoney"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="最大限额">
        <el-col :span="5">
          <el-select v-model="limit">
            <el-option
              v-for="(item,index) in limitOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          v-if="limit == 1"
          :span="18" :offset="1">
          <el-form-item prop="maxMoney">
            <el-input
              class="max-money"
              placeholder="请输入" v-model="form.maxMoney">
            </el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click.stop="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getByteLen, validatpositiveInt, limitEnAndSpace } from '@/utils/validate'
import { addPayment,editPayment } from '@/api/bussetup/paymentManage'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    payInfo:{
      type: Object,
    },
  },
  data() {
    let _this = this;
    let validateChanelName = (rule,value,callback) => {
      setTimeout(() => {
        if(getByteLen(value) > 20){
          callback(new Error('限制20个字符(10个汉字)'));
        }else {
          callback();
        }
      }, 500);
    };
    let validateMinMoney = (rule,value,callback) => {
      setTimeout(() => {
        if(!validatpositiveInt(value)){
          callback(new Error('请输入正整数'));
        }else {
          callback();
        }
      }, 1000);
    };
    let validateMaxMoney = (rule,value,callback) => {
      if(this.limit == 0){
        callback();
      }else {
        setTimeout(() => {
          if(!validatpositiveInt(value)){

            callback(new Error('请输入正整数'));
          }else {
            if((value - 0) < (_this.form.minMoney -0 )){
              return callback(new Error('请输入比最小值大的正整数'));
            }
            callback();
          }
        }, 1000);
      }
    };
    return {
      loading: false,
      form: {
        id: '',
        name: '',
        nameHant: '',
        nameEn: '',
        status: '0',
        type: '1',
        minMoney: '',
        currency: '',
        maxMoney: '',
      },

      rules:{
        name:[
          { required: true, message: '请填写支付方式名称', trigger: 'blur' },
          { validator: validateChanelName, trigger: 'blur' },
        ],
        nameHant:[
          { required: true, message: '请填写支付方式繁体名称', trigger: 'blur' },
          { validator: validateChanelName, trigger: 'blur' },
        ],
        nameEn:[
          { required: true, message: '请输入支付方式英文名称', trigger: 'blur' },
        ],
        status:[
          { required: true, message: '选择支付方式状态', trigger: 'change' },
        ],
        type:[
          { required: true, message: '请选择支付方式类型', trigger: 'change' },
        ],
        minMoney:[
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: validateMinMoney, trigger: 'blur' },
        ],
        maxMoney:[
          { validator: validateMaxMoney, trigger: 'blur' },
        ],
      },
      limitOptions:[
        {  label: '限制', value: 1 },
        {  label: '不限制', value: 0 },
      ],
      limit: 0,
    }
  },
  watch:{
    payInfo:{
      handler(newValue){
        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.assignmentFormCom(this.payInfo);
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
  },
  computed: {
    typeDisabled(){
      return !this.isAdd ? true : false;
    }
  },
  methods: {
    channelNameEnInputHandle(){
      this.form.nameEn = limitEnAndSpace(this.form.nameEn);
    },
    clearForm(){
      this.form = {
        name: '',
        nameHant: '',
        nameEn: '',
        lang: 'zh_CN',
        status: '0',
        type: '1',
        minMoney: '',
        maxMoney: '',
      };
    },
    cancel() {
      this.close();
    },
    close(){
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    assignmentFormCom(data){
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.nameHant = data.nameHant || '';
      this.form.nameEn = data.nameEn;
      this.form.status = data.status + '';
      this.form.type = data.oprType + '';
      this.form.minMoney = data.minimumLimit || '';
      this.form.maxMoney = data.maximumLimit || '';
      this.limit = data.maximumLimit == null ? 0 : 1;
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    doAdd() {
      let params = {
        name: this.form.name,
        nameHant: this.form.nameHant,
        nameEn: this.form.nameEn,
        status: Number(this.form.status),
        oprType: Number(this.form.type),
      };
      if(this.form.minMoney){
        params.minimumLimit = this.form.minMoney;
      }
      if(this.form.maxMoney){
        params.maximumLimit = this.form.maxMoney;
      }
      this.loading = true;
      addPayment(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.init();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let params = {
        id: this.form.id,
        name: this.form.name,
        nameHant: this.form.nameHant,
        nameEn: this.form.nameEn,
        status: this.form.status - 0,
        oprType: this.form.type - 0,
        minimumLimit: this.form.minMoney,
      }
      if(this.limit == 1){
        params.maximumLimit = this.form.maxMoney;
      }
      this.loading = true;
      editPayment(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.init();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.$refs['form'].clearValidate();
      this.$refs['form'].resetFields();
    },
    limitMaxChangeHandle(val){
      if(val === 1){
        this.$refs.form.clearValidate(['maxMoney']);
      }
    }
  }
}
</script>
<style lang="less">
  .pay-add-or-edit-dialog {
    .el-dialog__body {
      padding-left: 30px;
      padding-right: 30px;
    }
    .max-money {
      width: 100%;
    }
    .currency-min-select {
      width: 100px;
    }
    .currency-max-select {
      width: 80px;
    }
    .currency-max-select {
      .el-form-item__error {
        left: 100px!important;
      }
    }
  }
</style>
<style lang="less" scoped>
.pay-add-or-edit-dialog {
  .money-prefix {
    width: 80px;
  }
  .time-box {
    width: 100%;
  }
  .time-spilt {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .input-box-rate {
    width: 380px;
    position: relative;
    .ratesuffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }
  .upload-img {
    width: 50px;
    height: 50px;
  }
  .uppload-btn {
    color: skyblue;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .delete {
    cursor: pointer;
  }
  .icon-class {
    width: 100px;
    margin-top: 20px;
  }
}
</style>
